import { environment } from 'shared-lib';

import('./bootstrap')
	.catch(err => console.error(err));
	//fetchConfig();

// async function fetchConfig(){
// 	try{
// 		const response=await fetch('assets/config.json');
// 		const config=await response.json();
// 		console.log(config);
// 		let env:any=environment;
// 		Object.keys(config).forEach(key=>{
// 		if(environment.hasOwnProperty(key)){
// 			env[key as keyof typeof environment]=config[key];
// 		}
// 	  })	
// 	}
// 	catch(error){
// 		console.log(error);

// 	}
// }
	
